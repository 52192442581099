import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAdminAuth } from "../components/AdminAuthProvider";
import { BASE_URL } from "../env";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { Table, InputGroup, FormControl, Button } from "react-bootstrap";
import MemberDetailsModal from "../components/MemberDetailsModal";
import { IoSearch, IoEyeSharp, IoDocumentTextOutline } from "react-icons/io5";
// import { FaFileUpload } from "react-icons/fa";
// import CreditFileUploadModal from "../components/CreditFileUploadModal";
// import ConfirmationModal from "../components/ConfirmationModal";
// import { MdOutlineAddchart } from "react-icons/md";
// import BranchManagerAddLoanDetailsModal from "../components/BranchManagerAddLoanDetailsModal";
import { useLocation } from "react-router-dom";
import ActionButtons from "../components/ActionButtons";
// import UpdateMemberStatusModal from "../components/UpdateMemberStatusModal";
// import UpdateMemberStatusWithCreditAnalysisModal from "../components/UpdateMemberStatusWithCreditAnalysis";
import Zoom from "react-medium-image-zoom";
import { MdDateRange } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import MyProgressBar from "../components/ProgressBar";

const MemberDetails = () => {
  const location = useLocation();
  const { filterStatus } = location.state || {};
  const { adminUser } = useAdminAuth();

  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [memberData, setMemberData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  // const [showUploadModal, setShowUploadModal] = useState(false);
  // const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // const [confirmationMessage, setConfirmationMessage] = useState("");
  // const [confirmationAction, setConfirmationAction] = useState(null);
  // const [showLoanDetailsModal, setShowLoanDetailsModal] = useState(false);
  // const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  // const [
  //   showUpdateStatusWithCreditAnalysisModal,
  //   setShowUpdateStatusWithCreditAnalysisModal,
  // ] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };
  const fetchMemberDetails = useCallback(async () => {
    try {
      const params = {};
      if (filterStatus === "rejected") {
        params.sanctionCommitteeStatus = "rejected";
      }
      let getMemberDetails = "";
      if (adminUser.userType === "Branch Manager") {
        getMemberDetails = "getMemberDetailsByBranchManager";
        params.branchManagerId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.branchManagerStatus = ["submitted", "disbursed"];
        }
        if (filterStatus === "pending") {
          params.branchManagerStatus = "pending";
        }
        if (filterStatus === "retrigger") {
          params.branchManagerStatus = "retrigger";
        }
        if (filterStatus === "disbursed") {
          params.fieldManagerStatus = "submitted";
          params.branchManagerStatus = "submitted";
          params.creditOfficerStatus = "submitted";
          params.misStatus = "submitted";
          params.creditManagerStatus = "submitted";
          params.sanctionCommitteeStatus = "approved";
          params.accountManagerStatus = "pending";
        }
      }
      if (adminUser.userType === "Credit Officer") {
        getMemberDetails = "getMemberDetailsByCreditOfficer";
        params.creditOfficerId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.creditOfficerStatus = "submitted";
        }
        if (filterStatus === "pending") {
          params.creditOfficerStatus = "pending";
        }
        if (filterStatus === "retrigger") {
          params.creditOfficerStatus = "retrigger";
        }
      }
      if (adminUser.userType === "MIS") {
        getMemberDetails = "getMemberDetailsByMis";
        params.misId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.misStatus = ["submitted", "disbursed"];
        }
        if (filterStatus === "pending") {
          params.misStatus = "pending";
        }
        if (filterStatus === "retrigger") {
          params.misStatus = "retrigger";
        }
        if (filterStatus === "disbursed") {
          params.fieldManagerStatus = "submiited";
          params.branchManagerStatus = "disbursed";
          params.creditOfficerStatus = "submiited";
          params.misStatus = "submiited";
          params.creditManagerStatus = "submiited";
          params.sanctionCommitteeStatus = "approved";
          params.accountManagerStatus = "pending";
        }
      }
      if (adminUser.userType === "Credit Manager") {
        getMemberDetails = "getMemberDetailsByCreditManager";
        params.creditManagerId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.creditManagerStatus = "submitted";
        }
        if (filterStatus === "pending") {
          params.creditManagerStatus = "pending";
        }
        if (filterStatus === "retrigger") {
          params.creditManagerStatus = "retrigger";
        }
      }
      if (adminUser.userType === "Sanction Committee") {
        getMemberDetails = "getMemberDetailsBySanctionCommittee";
        params.sanctionCommitteeId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.sanctionCommitteeStatus = "approved";
        }
        if (filterStatus === "pending") {
          params.sanctionCommitteeStatus = "pending";
        }
        if (filterStatus === "retrigger") {
          params.sanctionCommitteeStatus = "retrigger";
        }
      }
      if (adminUser.userType === "Accounts Manager") {
        getMemberDetails = "getMemberDetailsByAccountsManager";
        params.accountManagerId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.accountManagerStatus = "payment credited";
        }
        if (filterStatus === "pending") {
          params.accountManagerStatus = "pending";
        }
        if (filterStatus === "retrigger") {
          params.accountManagerStatus = "retrigger";
        }
      }
      if (adminUser.userType === "superadmin") {
        getMemberDetails = "getMemberDetailsBySuperadmin";
        params.superadminId = adminUser.user.id;
        if (filterStatus === "approved") {
          params.accountManagerStatus = "payment credited";
        }
        if (filterStatus === "pending") {
          params.accountManagerStatus = "pending";
        }
      }
      const response = await axios.get(`${BASE_URL}/api/${getMemberDetails}`, {
        params,
      });
      // console.log("Response: " + JSON.stringify(response.data.list));
      setMemberData(response.data.list);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  }, [adminUser.user.id, adminUser.userType, filterStatus]);

  useEffect(() => {
    fetchMemberDetails();
  }, [fetchMemberDetails]);

  const filteredMembers = memberData.filter((member) => {
    // Convert the createdAt date to a locale date string
    const memberDate = new Date(member.createdAt)
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .toLowerCase();

    // Normalize the searchTerm
    const normalizedSearchTerm = searchTerm.toLowerCase();

    // Check if searchTerm matches any text fields or the date field
    const matchesTextFields =
      member.memberName.toLowerCase().includes(normalizedSearchTerm) ||
      member.branchName.toLowerCase().includes(normalizedSearchTerm) ||
      member.croName.toLowerCase().includes(normalizedSearchTerm);

    const matchesDateField = memberDate.includes(normalizedSearchTerm);

    // Return true if either text fields or date field matches the search term
    return matchesTextFields || matchesDateField;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMembers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredMembers.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleShowModal = (memberId) => {
    setSelectedMemberId(memberId);
    setShowModal(true);
  };

  // const handleShowUploadModal = (memberId) => {
  //   setSelectedMemberId(memberId);
  //   setShowUploadModal(true);
  // };
  // const updateMemberStatus = async (status, memberId) => {
  //   if (!memberId) {
  //     console.error("No member selected for status update.");
  //     return;
  //   }

  //   try {
  //     const response = await axios.put(
  //       `${BASE_URL}/api/creditmanager/status/${memberId}`,
  //       { creditManagerStatus: status }
  //     );

  //     setShowConfirmationModal(false);
  //     setSelectedMemberId(null);
  //     alert(response.data.message);
  //     fetchMemberDetails();
  //   } catch (error) {
  //     console.error("Error updating member status:", error);
  //     setSelectedMemberId(null);
  //   }
  // };

  // const handleUpdateMemberStatus = (
  //   message,
  //   memberId,
  //   approvalStatusFieldName,
  //   approvalMessageFieldName,
  //   approvalUpdatedDateFieldName,
  //   statusToUpdate,
  //   loanAmount
  // ) => {
  //   setConfirmationMessage(message);
  //   setFormData({
  //     ...formData,
  //     memberId,
  //     approvalStatusFieldName,
  //     approvalMessageFieldName,
  //     approvalUpdatedDateFieldName,
  //     statusToUpdate,
  //     loanAmount,
  //   });
  //   setShowUpdateStatusModal(true);
  // };

  // const handleCloseUpdateMemberStatus = () => {
  //   fetchMemberDetails();
  //   setShowUpdateStatusModal(false);
  // };

  // const handleUpdateMemberStatusWithCreditAnalysis = (
  //   message,
  //   memberId,
  //   approvalStatusFieldName,
  //   approvalMessageFieldName,
  //   approvalUpdatedDateFieldName,
  //   statusToUpdate,
  //   loanAmount
  // ) => {
  //   setConfirmationMessage(message);
  //   setFormData({
  //     ...formData,
  //     memberId,
  //     approvalStatusFieldName,
  //     approvalMessageFieldName,
  //     approvalUpdatedDateFieldName,
  //     statusToUpdate,
  //     loanAmount,
  //   });
  //   setShowUpdateStatusWithCreditAnalysisModal(true);
  // };

  // const handleCloseUpdateMemberStatusWithCreditAnalysis = () => {
  //   fetchMemberDetails();
  //   setShowUpdateStatusWithCreditAnalysisModal(false);
  // };

  // const handleApprove = (memberId) => {
  //   setSelectedMemberId(memberId);
  //   setConfirmationMessage("Are you sure you want to approve this member?");
  //   setConfirmationAction(() => () => {
  //     updateMemberStatus("accepted", memberId);
  //   });
  //   setShowConfirmationModal(true);
  //   alert("Error in updating member status");
  // };

  // const handleReject = (memberId) => {
  //   setSelectedMemberId(memberId);
  //   setConfirmationMessage("Are you sure you want to reject this member?");
  //   setConfirmationAction(() => () => {
  //     updateMemberStatus("rejected", memberId);
  //   });
  //   setShowConfirmationModal(true);
  // };

  // const handleShowLoanDetailsModal = (memberId) => {
  //   setSelectedMemberId(memberId);
  //   setShowLoanDetailsModal(true);
  // };

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <div className="member-details mt-4">
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <IoSearch />
              </InputGroup.Text>
              <FormControl
                placeholder="Search by Member Name or Branch Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>

            {/* <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th style={{ backgroundColor: '#100D40', color: 'white' }}>S.No</th>
                  <th style={{ backgroundColor: '#100D40', color: 'white' }}>Member Photo</th>
                  <th>Member Name</th>
                  <th>Phone Number</th>
                  <th style={{ backgroundColor: '#100D40', color: 'white' }}>Applied Date</th>
                  <th style={{ backgroundColor: '#100D40', color: 'white' }}>Branch Name</th>
                  <th style={{backgroundColor:'#100D40',color:'white'}}>Status</th>
                  <th style={{ backgroundColor: '#100D40', color: 'white' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((member, index) => {
                    const getStatusColor = (status) => {
                      switch (status) {
                        case 'pending':
                          return 'brown';
                        case 'approved':
                          return 'green';
                        case 'rejected':
                          return 'red';
                        case 'retrigger':
                          return 'orange';
                        default:
                          return 'black'; // Default color if no match
                      }
                    };

                    //                     const combinedStatus = `                    
                    //   Field Manager: ${member.fieldManagerStatus}
                    //   Branch Manager: ${member.branchManagerStatus}
                    //   Credit Officer: ${member.creditOfficerStatus}
                    //   MIS: ${member.misStatus}
                    //   Credit Manager: ${member.creditManagerStatus}
                    //   Sanction Committee: ${member.sanctionCommitteeStatus}
                    //   Accounts Manager: ${member.accountManagerStatus}
                    // `;

                    return (
                      <tr key={member.member_id} style={{ verticalAlign: 'middle' }}>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            <Zoom>
                              <img
                                src={`${BASE_URL}/images/${member.memberPhoto}`}
                                width={60}
                                height={60}
                                alt="Member"
                                style={{ borderRadius: 5, marginRight: 5 }}
                              />
                            </Zoom>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span>{member.memberName}</span>
                              <span>{member.phoneNumber}</span>
                            </div>
                          </div>
                        </td>
                        <td>{member.memberName}</td>
                        <td>{member.phoneNumber}</td>
                        <td> <MdDateRange style={{ marginRight: 5, fontSize: 50, padding: 10, borderRadius: 5, backgroundColor: '#100D4020', color: '#100D40' }} />{member.createdAt}</td>
                        <td><FaLocationDot style={{ marginRight: 5, fontSize: 45, padding: 10, borderRadius: 5, backgroundColor: '#100D4020', color: '#100D40' }} />{member.branchName}</td>
                        <td>
                          <div>
                            
                            <p className="mb-1" >
                              Field Manager: <span style={{ color: getStatusColor(member.fieldManagerStatus),fontWeight:'600' }}>{member.fieldManagerStatus}</span>
                            </p>
                            <p className="mb-1" >
                              Branch Manager: <span style={{ color: getStatusColor(member.branchManagerStatus),fontWeight:'600' }}>{member.branchManagerStatus}</span>
                            </p>
                            <p className="mb-1" >
                              Credit Officer: <span style={{ color: getStatusColor(member.creditOfficerStatus),fontWeight:'600' }}>{member.creditOfficerStatus}</span>
                            </p>
                            <p className="mb-1" >
                              MIS: <span style={{ color: getStatusColor(member.misStatus),fontWeight:'600' }}>{member.misStatus}</span>
                            </p>
                            <p className="mb-1" >
                              Credit Manager: <span style={{ color: getStatusColor(member.creditManagerStatus),fontWeight:'600' }}>{member.creditManagerStatus}</span>
                            </p>
                            <p className="mb-1">
                              Sanction Committee: <span style={{ color: getStatusColor(member.sanctionCommitteeStatus),fontWeight:'600' }}>{member.sanctionCommitteeStatus}</span>
                            </p>
                            <p className="mb-1" >
                              Accounts Manager: <span style={{ color: getStatusColor(member.accountManagerStatus),fontWeight:'600' }}>{member.accountManagerStatus}</span>
                            </p>
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                              <FaEllipsisV />
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ padding: '10px' }}>
                              <div style={{ display: 'flex', gap: '10px' }}>
                                <Dropdown.Item className="p-0">
                                  <Button
                                    variant="dark"
                                    // className="mb-2"
                                    onClick={() => handleShowModal(member.id)}
                                  >
                                    <IoEyeSharp />
                                  </Button>
                                </Dropdown.Item>

                                {[
                                  "Branch Manager",
                                  "Credit Manager",
                                  "MIS",
                                  "Sanction Committee",
                                  "Accounts Manager",
                                  "superadmin",
                                ].includes(adminUser.userType) && member.cbReport && (
                                    <Dropdown.Item className="p-0"
                                    >
                                      <Button
                                        variant="primary"
                                        className="mb-2"
                                        onClick={
                                          () =>
                                            window.open(
                                              ` ${BASE_URL}/images/${member.cbReport}`,
                                              "_blank"
                                            ) // Removed the extra curly brace
                                        }
                                      >
                                        <IoDocumentTextOutline />
                                      </Button>
                                    </Dropdown.Item>
                                  )}

                                <Dropdown.Item as="div" className="p-0">
                                  <ActionButtons
                                    roleId={adminUser.userType}
                                    item={member}
                                    updateList={fetchMemberDetails}
                                    managerId={adminUser.user.id}
                                  />
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No member data available.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table> */}

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                padding: "16px",
              }}
            >
              {currentItems.length > 0 ? (
                currentItems.map((member) => (
                  <div
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: "16px",
                      width: "100%",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      // display: 'flex',
                      justifyContent: "space-between",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div key={member.member_id} style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                          width: "35%",
                        }}
                      >
                        <Zoom>
                          <img
                            src={`${BASE_URL}/images/${member.memberPhoto}`}
                            width={60}
                            height={60}
                            alt="Member"
                            style={{ borderRadius: "5px", marginRight: "10px" }}
                          />
                        </Zoom>
                        <div style={{ textAlign: "left" }}>
                          <div>{member.memberName}</div>
                          <div>{member.ApplicationId}</div>
                          <Button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "black",
                              boxShadow: "none",
                              padding: 0,
                              textDecoration: "underline",
                            }}
                            onClick={() => handleShowModal(member.id)}
                          >
                            View Details
                            <FaAngleRight />
                          </Button>
                        </div>
                      </div>
                      <div style={{ marginBottom: "8px", width: "20%" }}>
                        <MdDateRange
                          style={{
                            marginRight: 5,
                            fontSize: 40,
                            padding: 10,
                            borderRadius: 5,
                            backgroundColor: "#100D4020",
                            color: "#100D40",
                          }}
                        />
                        {new Date(member.createdAt).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }
                        )}
                      </div>
                      <div style={{ marginBottom: "8px", width: "20%" }}>
                        <FaLocationDot
                          style={{
                            marginRight: 5,
                            fontSize: 35,
                            padding: 10,
                            borderRadius: 5,
                            backgroundColor: "#100D4020",
                            color: "#100D40",
                          }}
                        />
                        {member.branchName}
                      </div>
                      <div style={{ marginBottom: "8px", width: "20%" }}>
                        CRO: {member.croName}
                      </div>
                      <div style={{ marginBottom: "8px", width: "20%" }}>
                        Loan Amount: Rs.
                        {member.proposedLoanAmount}
                      </div>
                      <div style={{ width: "15%" }}>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                          >
                            {/* <FaEllipsisV /> */}
                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{ padding: "10px" }}>
                            <div style={{ display: "flex", gap: "10px" }}>
                              <Dropdown.Item className="p-0">
                                {/* <Button
                                variant="dark"
                                onClick={() => handleShowModal(member.id)}
                              >
                                <IoEyeSharp />
                              </Button> */}
                              </Dropdown.Item>

                              {[
                                "Branch Manager",
                                "Credit Manager",
                                "MIS",
                                "Sanction Committee",
                                "Accounts Manager",
                                "superadmin",
                              ].includes(adminUser.userType) &&
                                member.cbReport && (
                                  <Dropdown.Item className="p-0">
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        window.open(
                                          `${BASE_URL}/images/${member.cbReport}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <IoDocumentTextOutline />
                                    </Button>
                                  </Dropdown.Item>
                                )}

                              {[
                                "Branch Manager",
                                "Credit Manager",
                                "MIS",
                                "Sanction Committee",
                                "Accounts Manager",
                                "superadmin",
                              ].includes(adminUser.userType) &&
                                member.coApplicantCbReport && (
                                  <Dropdown.Item className="p-0">
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        window.open(
                                          `${BASE_URL}/images/${member.coApplicantCbReport}`,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <IoDocumentTextOutline />
                                    </Button>
                                  </Dropdown.Item>
                                )}

                              <Dropdown.Item as="div" className="p-0">
                                <ActionButtons
                                  roleId={adminUser.userType}
                                  item={member}
                                  updateList={fetchMemberDetails}
                                  managerId={adminUser.user.id}
                                  proposedLoanAmount={member.proposedLoanAmount}
                                  rateOfInterest={
                                    member.proposedLoanDetails.rateOfInterest
                                  }
                                  tenureInMonths={
                                    member.proposedLoanDetails.tenureInMonths
                                  }
                                />
                              </Dropdown.Item>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    {/* <div className="mx-3 mt-3">
                      <MyProgressBar />
                    </div> */}
                  </div>
                ))
              ) : (
                <div className="text-center w-100">
                  No member data available.
                </div>
              )}
            </div>

            {/* Custom Pagination */}
            <nav className="custom-pagination">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link mx-2"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                <li key={currentPage} className="page-item active">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage)}
                  >
                    Page {currentPage} of {totalPages}, Total Data:{" "}
                    {filteredMembers.length}
                  </button>
                </li>
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link mx-2"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {showModal && (
        <MemberDetailsModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          memberId={selectedMemberId}
        />
      )}

      {/* {showUploadModal && (
        <CreditFileUploadModal
          show={showUploadModal}
          handleClose={() => setShowUploadModal(false)}
          memberId={selectedMemberId}
          creditManagerId={adminUser.user.id}
        />
      )} */}
      {/* <ConfirmationModal
        show={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={confirmationAction}
        message={confirmationMessage}
      /> */}
      {/* {showLoanDetailsModal && (
        <BranchManagerAddLoanDetailsModal
          show={showLoanDetailsModal}
          handleClose={() => setShowLoanDetailsModal(false)}
        />
      )}
      {showUpdateStatusModal && (
        <UpdateMemberStatusModal
          show={showUpdateStatusModal}
          handleClose={handleCloseUpdateMemberStatus}
          message={confirmationMessage}
          formData={formData}
        />
      )} */}
      {/* {showUpdateStatusWithCreditAnalysisModal && (
        <UpdateMemberStatusWithCreditAnalysisModal
          show={showUpdateStatusWithCreditAnalysisModal}
          handleClose={handleCloseUpdateMemberStatusWithCreditAnalysis}
          message={confirmationMessage}
          formData={formData}
          memberId={formData.memberId}
          creditOfficerId={adminUser.user.id}
        />
      )} */}
    </div>
  );
};

export default MemberDetails;
